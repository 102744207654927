@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&family=Oxygen:wght@700&display=swap');

/* *{
  font-family: 'Mulish', sans-serif;
} */

.footer {
  background-color: #000000;
  color: #fff;
  padding: 30px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  flex: 1;
}

.col h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.col ul {
  list-style: none;
  padding: 0;
}

.col ul li {
  margin-bottom: 10px;
}

.col ul li a {
  color: #fff;
  text-decoration: none;
}

.copyright {
  text-align: center;
  margin-top: 30px;
}

.mainfoot {
  text-align: justify;
  text-justify: inter-word;
}

.mainfoot {
  /* background-image: url('../../images/bg.svg'); */
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  background-image:linear-gradient(to bottom,#000000, #e6ff022e);
  /* Fill: Solid rgba(15, 42, 112, 0.5); */
  min-height: 60rem;

  min-height: 30rem;
  max-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  /* border-radius: 25px; */
  z-index: 5;
}

.social li a {
  color: white;
}

.footup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footabout {
  margin-top: 0px;
  display: flex;
  border-right: 6px solid #EFED34;
  width: 50%;
  height: 70%;
  text-align: center;
  margin-left: 4%;
  padding-bottom: 10px;
}

.content {
  height: 90%;
  width: 90%;
  /* margin-left: 15rem; */
  margin-top: 5vw;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 2.7rem;
  font-weight: 400;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  /* font-size: 4.9rem;  */
  /* initial val =4.9 */

  /* font-weight:420; */
  /* margin: -5%; */
  position: absolute;
  left: 13%;
  width: 10%;
  color: white;
  transform: translateY(-2rem);
}

.logo span {
  font-size: 3vw;
  /* min-height: 50px; */
  font-weight: 420;
}

.logo img {
  height: 5vw;
  width: 5vw;
}

.form {
  text-align: center;
  /* margin-left: 15%; */
  /* margin-right: -20%; */
  margin-top: 4vw;
  width: 80%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-left: 8rem;
  /* background-color: red; */
  text-align: center;
  /* align-items: center; */
  outline: none;

}

.forms input {
  /* outline: none;
  font-family: 'Mulish', sans-serif;
  /*width: 90%;
  height: 4rem;
  border-radius: 6px;
  font-weight: 10;
  font-size: 1.8rem;
  padding: 0.3rem;
  padding-left: 1rem;
  border: 1px solid black; */
  width: 90%;
  padding: 0.5rem 1rem 1rem 1rem;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  /* border-bottom: 2px solid #888; */
  color: white;
  font-size: 0.6rem;
  /* outline: none; */
  transition: border-color 0.3s ease;
  background: #ffffff00;
  box-shadow: none;
  outline: none;
  font-size: 2rem;
  stroke: solid #817676;
  transition: 0.3s;
}

.forms input::placeholder, .forms textarea::placeholder {
  color: #666;
  font-weight: 400;
}

.forms textarea {
  outline: none;
  width: 90%;
  height: 16rem;
  font-size: 1.8rem;
  border-radius: 6px;
  text-align: left;
  padding: 0.3rem;
  padding-left: 1rem;
  font-weight: 400;
  resize: none;
  border: none;
}

.forms button {
  font-family: 'Mulish', sans-serif;
  width: 8rem;
  height: 3.5rem;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-family: 'Mulish', sans-serif;
  line-height: 0;
  border-radius: 5px;
  font-weight: 700;
  outline: none;
  font-size: 1.5rem;
  border: 1px solid black;
  transition: 0.3s;
}

.forms button:hover {
  cursor: pointer;
  background-color: #EFED34;
  color: #000000;
  transition: 0.3s;
  transform: scale(1.05);
  /* font-weight: 900;
  border-radius: 10px;
  width: 10rem; */
}

.h2{
  font-size: large;
  font-weight: 600;
  text-align: center;
  width: 100%;
  color: white;
}

.forms input, textarea{
  background-color: #efec34e9;
  font-family: 'Mulish', sans-serif;
  color: rgb(0, 0, 0);
}

.form h2 {
  color: white;
  text-align: left;
  margin-bottom: -4px;
  font-weight: 700;
  font-size: 2.5rem;
}

.textarea {
  transition: all 0.2s ease-in-out;
}

.inputHover:hover, .inputFocus:focus {
  box-shadow: #fffeafab 1px 2px 4px 1px;
  transform: scale(1.01) translateY(-3px);
}

/* .footdown {
  display: flex;
  position: relative;
  top: 1rem;

} */

.footdown {
  position: relative;
  /* top: 0.2rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: aqua; */
  /* height: 3rem; */
  width: 100%;
  /* background-color:red ; */


}

.social {
  display: flex;
  /* border: 2px solid red; */
  /* margin-left: 5%;
  margin-top: -2%;
  height: 4rem; */
  width: 50%;
  justify-content: space-around;
  align-items: center;
}

.social ul {
  display: flex;
  flex-direction: row;
  font-size: 2vw;
  justify-content:left;
  align-items: center;
  transform: translateX(-2.5rem);
  padding-bottom: 2rem;
}

.social ul li {
  margin-top: 0vw;
  /* margin-right: 5vw; */
  transition: 0.3s;
  margin: 0 2.5rem;
}
/* 
.ii{
  height: 2rem;
  width: 2rem;
  background-color: white;
  border-radius: 10rem;
  color: rgb(10, 10, 83);
} */

.ii:hover{
  color:  rgb(236, 255, 69);
}

.social ul li:hover{
  transform: scale(1.07);
  transition: 0.2s;
}

.copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 4rem;
  width: 60%;
  /* margin-left: -1rem; */
  /* margin-top: -2rem; */
}

.copy h3 {
  color: white;
}

.copy span {
  font-size: 1.8rem;
  color: white;
  font-weight: bolder;
  padding: 0.1rem;
}

@media (max-width:1000px){
  .social ul li{
    margin: 0 2rem;
  }
}

@media (max-width:850px) {
  .mainfoot {
    display: flex;
    flex-direction: column;
    height: 75rem;
  }

  .footup {
    display: flex;
    flex-direction: column;
  }

  .footabout {
    border: 0px;
    display: flex;
    flex-direction: column;
    margin-right: 23%;
  }
  .forms textarea{
    margin-top: 1rem;
    height: 5rem;
  }
  .logo {
    display: flex;
    width: 67%;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 1rem;
    /* font-size: large; */
    transform: translateY(0);
  }

  .copy{
    margin-top: 1rem;
  }

  .logo img {
    height: 8rem;
    width: 8rem;
  }

  .content {
    margin-top: 30%;
    width: 120%;
    align-items: center;
    text-align: center;
    /* background-color: red; */
  }

  .content p {
    font-size: 1.6rem;
    width: 120%;
    /* text-align: center; */
  }

  .logo span {
    font-size: 3.3rem;
  }

  .footdown {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 0rem;
    /* background-color:  #a473ce; */
    /* background-color: red; */
    /* background: #0F2A7080; */
  }

  .social {
    margin-left: 20vmin;
    margin-right: 20vmin;
    width: 60vmin;
    margin-top: 2rem;
    display: flex;
  }

  .social ul{
    transform: translateX(0);
    margin-top: 2.5vw;
  }

  .social ul li{
    margin: 0 2.5rem;
  }

  .form {
    width: 100%;
    margin-left: -10%;
  }
  .forms{
    width: 100%;
  }
  .forms input {
    width: 100%;
  }

  .forms textarea {
    width: 100%;
  }

  .footdown {
    height: 6rem;
    /* background-color:  #a473ce; */

  }

}

@media (max-width:620px) {

  .logo{
    margin-left: -2rem;
  }
  .mainfoot{
    height: 80rem;
    
  }

  .social{
    width: 90vmin;
    margin-left: 5vmin;
    margin-right: 5vmin;
  }

  .content {
    margin-top: 8rem;
    width: 180%;
  }

  .content p {
    font-size: 1.6rem;
    width: 100%;
  }

  .footabout {
    margin-left: -25%;
  }

  /* .logo {
    margin-left: 15%;
  } */

  .content p{
    margin-top: 1rem;

    margin-left: 1rem;
  }

  .copy {
    width: 90%;
  }

  .form {
    width: 100%;
    /* padding:; */
    /* margin-left: -15%; */
  }
  .forms{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding-left: 8rem;
    /* background-color: red; */
    text-align: center;
    /* align-items: center; */
    outline: none;
  }
  .forms input {
    height: 3rem;
    width: 96%;
    margin-top: 3%;
  }

  /* .forms {
    gap: 1rem;
    align-items: center;

  } */

  .forms textarea {
    /* height: 4.5rem; */
    width: 96%;
  }

  .form h2 {
    width: 100vw;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
    /* margin-left: -1%; */
    /* padding: 0; */
  }

  /* .footdown { */
    /* height: 6rem; */
    /* background-color:  #a473ce; */

  /* } */

  .copy{
    margin-top: 1rem;
  }

  .social{
    margin-top: 4rem;
  }

}

@media (max-width:440px) {
  
  .mainfoot{
    height: 80rem;
    
  }
  .social ul li{
    margin: 0 1.5rem;

  }

  .social{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 4rem;
  }
  /* .content {
    margin-top: 18%;
    width: 180%;
  }

  .content p {
    font-size: 1.2rem;
  }

  .footabout {
    margin-left: -25%;
  }

  .logo {
    margin-left: 14%;
    margin-top: -2%;
  }

  .copy {
    width: 90%;
  }*/

  .form {
    width: 100%;
    margin-left: -20%;
  }
/*
  .forms input {
    height: 2.5rem;
    width: 80%;
    gap: 0.2rem;
  }

  .form h2 {
    font-size: 1.6rem;
    margin-bottom: 3%;
  }

  .forms {
    gap: 0.1rem;
  }

  .forms textarea {
    height: 4.5rem;
    width: 80%;
    margin-top: 2%;
  } */

}
/* 
@media (max-width:380px) {
  .logo {
    margin-left: 10%;
    margin-top: -3%;
  }

  .copy {
    font-size: 0.7rem;
  }

  .forms h2 {
    font-size: 1.5rem;
    margin-left: 25%;
  }

  .forms input {
    width: 100%;
  }

  .forms textarea {
    width: 100%;
  }

  .forms button {
    height: 2.8rem;
    width: 6rem;
  }


} */

/* @media (max-width:355px) {

  
  .logo {
    margin-left: 9%;
    margin-top: -3.5%;

  }

  .logo span {
    font-size: 2.2rem;
  }

  .logo img {
    height: 4.2rem;
    width: 4.2rem;
  }

  .copy {
    font-size: 0.6rem;

  }

  .content p {
    font-size: 0.8rem;
  }

  .form {
    margin-left: -30%;
  }

  .forms h2 {
    font-size: 1.5rem;
    margin-left: 40%;
    width: 170%;

  }

  .forms input {
    width: 120%;
  }

  .forms textarea {
    width: 120%;
  }


} */

/* @media (max-width:270px) {

  .logo {
    margin-left: 9%;
    margin-top: -3.9%;

  }

  .logo span {
    font-size: 1.5rem;
  }

  .logo img {
    height: 3.5rem;
    width: 3.5rem;
  }

  .copy {
    font-size: 0.6rem;

  }

  .content p {
    font-size: 0.5rem;
  }

  .form {
    margin-left: -20%;
  }

  .forms h2 {
    font-size: 1.3rem;
    margin-left: 35%;
    width: 200%;

  }

  .forms input {
    padding-left: 3%;
    width: 120%;
    height: 1.8rem;
    font-size: 1.3rem;
  }

  .forms textarea {
    width: 120%;
    height: 3.5rem;
    font-size: 1.3rem;
  }


} */