/* *{
    box-sizing: border-box;
    
  }
  .background{
    height : 100vh;
    position: relative;
    width : 100%;
    background-color :#2C0333 ;
    overflow: hidden;
    background-image : url('https://i.postimg.cc/YqkfrQn3/background-comp.png');
    background-size : 100vw 82vh ;
  } */
  /* .imgcontainer{
  
  background-size: cover;
  background-repeat: no-repeat;
    width: 100vw;
    height: 90vh;
  } */
  /* .heading{
    font : "Ubuntu",sans-serif;
    color : white;
    font-size: 7em;
    text-align: center;
    min-width : 60vw;
    min-height : 20vh;
    margin:auto;
    margin-top : 15vh;
    margin-bottom : 7vh ;
    text-shadow: 0px 0px 45px black;
    font-weight : 400;
  }
  
  .cardfront>img{
    position: relative;
   
  }
  .cardcontainer{
    min-width: 15vw;
    min-height: 60vh;
    background-color: #07000B;
    box-shadow: 0px 0px 50px pink ;
    border-radius: 2px;
  }
  .cardfront{
  color : white;
  font-size: 1.5rem;

  }
  .cardback ,.cardfront{
    backface-visibility: hidden;
    position: absolute;
    width : 100%;
    height: 100%;
   
  
  }
  
  .flexcontainer{
    display: flex;
    flex-direction: row;
   justify-content: space-evenly;
  }
  .bottom{
    min-width : 100vw;
    min-height : 18vh;
    background-color: #07000B;
    position: absolute;
    bottom:0px;
    
    
  }
  .cardback{
    padding : 1em;
    justify-content: center;
    color : white;
    margin: auto;
    text-align: center;
    font-size: 2em;
    transform: rotateY(180deg);
    
  }
  .flipcontainer{
    position: relative;
    width : 100%;
  height : 100%;
  text-align: center;
  transition : transform 0.8s;
  transform-style : preserve-3d ;
  }
  .cardcontainer:hover .flipcontainer{
    transform: rotateY(180deg);
  }
  
  .img > img {
    width : 100% ;
  }
  @media (max-width:720px) {
    .cardcontainer{
        width : 25vw
    }
    .heading {
        font-size: 5em;
    }
  } */
  /* @media (max-width:720px) {
    .Responsibility{
        flex-direction: column;
        margin: 0;
        padding: 045;
    }
    .heading {
        font-size: 5em;
    }
  } 
   */


  /* body{
    color: white;
  } */
  .Responsibilities h1{
    font-size: 4rem;
    margin-top: 2rem;
    text-align: center;
    /* border: 2px solid white; */
    color: white;

  }
  .Responsibilities{
    display: flex;
    flex-direction: column;
    padding:0 50px;
    /* border: 2px solid white; */
    background-color: black;
    overflow: hidden;
    /* border: 2px solid white; */
    color: white;
  }
  .container{
    display: flex;
    /* gap: 20px; */
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin: 0 2rem;
    scroll-behavior: smooth;
    /* border: 2px solid white; */
    /* width: 250px; */
    transition: 1s;
  }
  .headerscontainer{
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin: 0 2rem;
    scroll-snap-type:x mandatory;
    scroll-behavior: smooth;
    transition: 1s ease;
    /* border: 2px solid white; */

    
  }
  .Responsibility{
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    width: 100%;
    padding:1rem 15rem; 
    animation:textanimation;
    animation-duration: 2s;
  }
  .hide{
    opacity: 0.3;
  }
  .nav_head{
    font-size: 4rem;
    margin: 0 4rem;
    opacity:0.5;
    cursor:pointer;
    transition: 2s;
  }
  /* .active_head::after{
    border:2px solid white;
    transition: ;
  } */
  .active_head{
    border-image: linear-gradient(135deg, #e3ea0b, #e9fe27) 30;
    border-width: 0.6rem;
    opacity: 1 ;
    align-self: center;
    text-align: center;
    border-bottom: 2px solid white;
    scroll-snap-align: center;
    animation:headersanimation;
    animation-duration: 2s;
    /* text-align: center; */
    /* border-style:double; */
    text-shadow: -1rem -0.5rem 2rem #f701b244,
                1rem 0.5rem 2rem #01a7fe44,
                inset 1rem 0.5rem 2rem #f701b244,
                inset -1rem -0.5rem 2rem #01a7fe44;
  }
  @keyframes headersanimation{
    from{
      opacity:0.2;
    }
    to{
      opacity:1;
    }
  }

  .answer_container{
    padding:0 5rem;
    margin-top: 5rem;
    
  }
  .textbox{
    font-size: 3rem;
    /* border-spacing: 1rem; */
    font-family: 'Mulish', sans-serif;
    width: 60%;
    text-align: left;
  }
  @keyframes textanimation{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }

  .imagebox{
    width: 40%;
    min-height: 40rem;
    /* border: 2px solid white; */
  }
  @media (max-width:350px){
    .imagebox img{
      width:20rem;
      height: 20rem;
    }
  }
  @media (max-width:720px){
    .imagebox img{
      width:25rem;
      height: 25rem;
    }
    .answer_container{
      margin-top: 0;
    }
    .Responsibilities h1{
      font-size: 3rem;
      text-align: center;
    }
    .imagebox{
      /* display: none; */
      /* border: 2px solid white; */
      width: 100%;
      display: flex;
      justify-content: center;
      min-height: 25rem;
    }
    .Responsibility{
      width: 100%;
      /* margin: 2rem; */
      min-height: 30rem;
      
      flex-direction: column-reverse;
      /* border: 2px solid red; */

    }
    .textbox{
      width:100%;
      /* border: 2px solid white; */

    }
    .Responsibilities{
      padding: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .active_head{
      border-bottom: none;
    }

    .nav_head{
      font-size: 2.5rem;
    }

  }





  @media (max-width:1090px){
    
      .headerscontainer{
        overflow-x: scroll;
        scrollbar-width:none;
        margin: 0;
        /* border: 2px solid white; */
      }
      .Responsibility{
        /* flex-direction: column-reverse; */
        padding: 0;
      }
      .textbox{
        /* width: 100%; */
        text-align: left;
        justify-self: center;
        font-size: 2rem;
        text-wrap:wrap;
        /* border: 2px solid white; */

      }
      
  }
  