.cardMain{
    /* background-color: rgba(159, 152, 152, 0.526); */
    background-color: #333131ee;
    border-radius: 2px;
    padding: 5px;
    width: 290px;
    height: 400px;
    color: #e3ea0b;
}
.cardHead{
    display:flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
}
.cardHeadImg img{
    width: 40px;
    background-color: #e3ea0b;
    border-radius: 10px;
    height: 40px;
}
.cardHeadDesc p:nth-child(1){
    font-size: 23px;
}
.cardDesc{
    padding: 10px;
    font-size: 19px;
}