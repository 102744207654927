.container{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Mulish', sans-serif;
    color: #fff;
    margin: 4rem 0 4rem 0;
}

.visitlink {
    background: #e4ed10;
    font-size: 1.5rem;
    color: #000;
    text-decoration: none;
    font-weight: 900;
    padding: 1rem 2rem;
    gap: 1rem;
    width: max-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 1rem;
    transition: all 0.3s;
    margin-top: 1rem;
}

.visitlink:hover {
    box-shadow: 0 0 2rem #e4ed10;
}

.heading{
    font-size: 4rem;
    padding-left: 1rem;
    padding-bottom: 4rem;
    text-align: center;
    font-family: 'Mulish', sans-serif;
    color: #fff;
    text-shadow: 0 0 4rem #000000;
}

.cardContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 4rem;
}

.topLeft{
    font-size: 1.5rem;
    z-index: 2;
    position: absolute;
    top: .5rem;
    left: .5rem;
    background-color: rgba(0, 0, 0, 0.622);
    padding: .4rem;
    border-radius: .4rem;
}




.card {
    overflow: visible;
    width: 254px;
    height: 254px;
  }

  .card .backImg {
    height: 80%;
    width: 80%;
    border-radius: 3rem;
  }
  
  .content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
  }
  
  .front, .back {
    background-color: #151515;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .back {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .back::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 160px;
    height: 160%;
    background: linear-gradient(90deg, transparent, #E8FB23, #E8FB23, #E8FB23, #E8FB23, transparent);
    animation: rotation_481 5000ms infinite linear;
  }
  
  .backContent {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #151515;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .card:hover .content {
    transform: rotateY(180deg);
  }
  
  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }
  
  .front {
    transform: rotateY(180deg);
    color: white;
  }
  
  .front .frontContent {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .frontContent .badge {
    background-color: #00000055;
    padding: 2px 10px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    width: fit-content;
  }
  
  .description {
    box-shadow: 0px 0px 10px 5px #00000088;
    width: 100%;
    padding: 10px;
    background-color: #00000099;
    backdrop-filter: blur(5px);
    border-radius: 5px;
    height: 100%;
  }

  .description > p {
    /* font-size: 0.9rem; */
    line-height: 1.2;
  }
  
  .title {
    font-size: 1.8rem;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .title p {
    width: 50%;
  }
  
  .card-footer {
    color: #ffffff88;
    margin-top: 5px;
    font-size: 1rem;
  }
  
  .front .img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }  
  
  .circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #ffbb66;
    position: relative;
    filter: blur(15px);
    animation: floating 2600ms infinite linear;
  }
  
  #bottom {
    background-color: #ff8866;
    left: 50px;
    top: 0px;
    width: 150px;
    height: 150px;
    animation-delay: -800ms;
  }
  
  #right {
    background-color: #ff2233;
    left: 160px;
    top: -80px;
    width: 30px;
    height: 30px;
    animation-delay: -1800ms;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0px);
    }
  
    50% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }

  @media screen and (max-width: 700px) {
    .cardContainer{
        padding: 0;
    }
  }