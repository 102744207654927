@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


form *{
  /* font-family: 'Poppins'; */
  font-family: 'Mulish', sans-serif;
}

.formArea {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(44, 3, 51);
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

h2 {
  color: #fff;
  text-align: left;
  text-shadow: 0px 0px 29.994037628173828px #000,
    0px 0px 59.988075256347656px #000, 0px 0px 209.95826721191406px #000,
    0px 0px 419.9165344238281px #000, 0px 0px 719.8568725585938px #000,
    0px 0px 1259.7496337890625px #000;
  font-size: 5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2rem;
}

.form__formDiv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 1rem;
}

.form__imgDiv {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../../images/signin_img.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.8;
}

.form__formarea {
  width: 50%;
  min-height: 100vh;
  backdrop-filter: blur(10px);
}

@media screen and (max-width: 1000px) {
  .form__formarea {
    width: 58%;
    padding: 1.5rem;
  }
}

@media screen and (max-width: 750px) {
  .form__formarea {
    width: 100%;
    padding: 0.5rem;
  }
}

.form__group {
  padding: 10rem 6rem 6rem 6rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: left;
  background-size: cover;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .form__group {
    padding: 10rem 3rem 0rem 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .form__group {
    padding: 10rem 4rem 6rem 4rem;
  }
}

.form__input {
  width: 100%;
  padding: 0.5rem 1.5rem 1rem 0rem;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #888;
  color: white;
  outline: none;
  transition: border-color 0.3s ease;
  background: #ffffff00;
  box-shadow: none;
  outline: none;
  font-size: 2rem;
  font-weight: 400;
  stroke: solid #817676;
}
.form__input:focus {
  border-bottom: 2.5px solid #EFED34;
}
/* .form__input:focus:invalid {
  outline-color: orange;
  outline-style: groove;
} */
.form__input:placeholder-shown ~ .form__label {
  opacity: 0;
}
.form__input::placeholder {
  color: #888;
}

.form__label {
  color: #fff;
  text-align: justify;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-shrink: 0;
}

.form_remforgot {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-left: 0;
}
.form_remforgot p {
  margin: 0;
  color: whitesmoke;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  text-align: right;
}

.form_remforgot_forgotp {
  width: 100%;
}
.remforgot{
  font-size: 1.5rem;
}
.remforgot:hover{
  text-decoration: underline;
}

.form__button {
  color: black;
  width: 100%;
  padding: 1em;
  border-radius: 1rem;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 1.5rem;
  background-color: #EFED34;
  transition: all 0.2s ease;
}
.form__button:hover {
  background-color: #fbf96f;
}

.form__redirect {
  color: #fbfcfe;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1rem;
  margin-top: 1rem;
}

.form__redirect span {
  color: #faf4f8;
  font-size: 1.25rem;
  text-decoration: underline;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
