.Container {
    display: flex;
}

.TotalDiv {
    background-image: url("dash kshitijca.png");
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
    
     
}
.share{
    font-Family: monospace;
     font-Size: 1.7rem;
      line-height: 1.6rem;
      position: relative;
      top: 3.8rem;
     
}

.LeftDiv {
    height: 92%;
    width: 22%;
    left: 0px;
    top: 6.4rem;
    border-radius: [object Object]px;
    position: relative;
    background-color: #3e4a59;
    background-color: rgb(62, 74, 89);
    background-blend-mode: multiply;
}

.TopDiv {
    height: 6rem;
    width: 18.2rem;
    left: 32.5rem;
    top: 10rem;
    border-radius: 0.85rem;
    background-color: green;
    position: relative;
  
}

.Div1 {
    height: 13rem;
    width: 17rem;
    left: 33rem;
    top: 18.5rem;
    border-radius: 0.85rem;
    background-color: white;
    position: relative;
    float: left;
    margin: 1rem;
    overflow-x: hidden;
    background-image: url("caa.png");
   
    

}

.Div2 {
    height: 13rem;
    width: 17rem;
    left: 33rem;
    top: 18.5rem;
    border-radius: 1rem;
    background-color: white;
    position: relative;
    float: left;
    margin: 1rem;
    clear: both;
  
}

.Over1 {
    text-align: center;
    font-family: 'Mulish', sans-serif;
    color: black;
    display: none;
    
}

.Div1:hover .Over1 {

    display: block;
}

.NameOf {
    height: 6%;
    width: 85%;
    left: 8%;
    top: 39%;
    border-radius: nullpx;
    color: #FFFFFF;
    /* font-family: 'Mulish', sans-serif; */
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;
    letter-spacing: 0em;
    text-align: center;
    position: absolute;
    
}

.RewardP {

    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    height: 18%;
    width: 65%;
    left: 16%;
    top: 60%;
    border-radius: -5px;
    background-color: #FFFFFF;
    position: absolute;
    border-style: dotted;
    border-width: thick;
    background-image: radial-gradient(circle at 0.625rem 1rem, #fff 12px, transparent 13px);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    flex-wrap: wrap;
    flex-direction: row;
   
}

.ProfilePic {
    height: 16rem;
    width: 16rem;
    left: 5%;
    top: 15%;
    border-radius: 8.5rem;
    position: absolute;
    background-color: white;
    overflow: hidden;
}

.ChangeAvatar {
    height: 3rem;
    width: 16rem;
    left: 7.5rem;
    top: 29rem;
    border-radius: 0.5rem;
    background-color: white;
    position: absolute;
    border: none;
    
}

p {
    margin: 0.3rem;
    font-size: 1.8rem;
    /* font-weight: bold; */
   
}

/* img {
    width: 30rem;
} */

.Button{
    position:absolute;
    top:15%;
    left:8%;
    width:18.5%;
    /* height:13%; */
    padding:1rem;
    font-size:3rem;
    font-weight: bolder;
    color:#0f4d70;
    border-radius:22px;
    background-color:white;
    border:4px solid white;
    margin-left: 30rem;
    /* cursor:pointer; */
    transition-duration:0.4s;
    z-index: 40;
}

.Button:hover {
    background-color:#0f4d70;
    color: white;
    transform: scale(0.95);
}

.cardpos {
    position: absolute;
    top: 19%;
    left: 30%;
    width: 70%;
    height: 65%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    overflow-y: scroll;
    cursor: pointer;
    
    /* height: 90vh; */
}
.ktjid{
    font-size: 1.6rem;
     margin-top: 0.5rem;
}



@media (max-width: 1301px){
    .RewardP {
        font-size: 1.75rem;
    height: 17%;
    width: 64%;
    left: 15%;
    top: 59%;
    
    }
    .cardpos {
        width: 68%;
        height: 63%;
     
        width: 70%;
    }
   
}
@media (max-width: 1148px){
    .ProfilePic{
        left: 3%;
        height: 15rem;
    width: 15rem;
    }
    .RewardP {
        font-size: 1.3rem;
        height: 16%;
        width: 63%;
        left: 15%;
        top: 61%;
    }
    .NameOf{
        font-size: 2.2rem;
    }
    .share{
        font-size: 1.4rem;
      
        /* margin-top: 5%; */
       
       
    }
    .ktjid{
        font-size: 1.5rem;
    }
    .Button{
        width: 24%;
    }
    .cardpos {
        width: 66%;
        height: 61%;
        width: 70%;
    }
}
/* @media (max-height:814px) {
    .share{
       
       position: relative;
       top: 2rem;
    }
    
} */
/* @media (max-width: 882px){
    .ProfilePic{
        left: 1.5%;
        height: 13rem;
    width: 13rem;

    }
    .LeftDiv{
        top: 6rem;
    }
    .NameOf{
        font-size: 2rem;
    }

    .RewardP {
        font-size: 1.1rem;
    height: 15%;
    width: 62%;
    left: 14%;
    top: 63%;
    }
    .ktjid{
        font-size: 1.1rem;
    }
    .share{
        font-size: 1.2rem;
       
    }
    .Button{
        width: 28%;
    }
    .cardpos {
        width: 62%;
        height: 58%;
        width: 70%;
    }
} */
 
@media (max-width: 1088px){
    .ProfilePic{
      height: 6.5rem;
      width: 6.5rem;
      top: 7.2rem;
    }
    .share{
    
        display: none;
    }
    .RewardP{
       
       
        font-size: 1.2rem;
        height: 15%;
        width: 22%;
        left: 72%;
        top: 2.5rem;
        border-radius: -5px;
        background-color: #FFFFFF;
        position: absolute;
        border-style: dotted;
        border-width: thick;
        background-image: radial-gradient(circle at 0.625rem 1rem, #fff 12px, transparent 13px);
      
        display: flex;
        font-weight: 700;
        flex-wrap: wrap;
        flex-direction: column;
        
    }
    
     
        .NameOf {
            height:3rem;
            width: 20rem;
            left: 11%;
            top: 2.9rem;
            border-radius: nullpx;
            color: #FFFFFF;
            /* font-family: 'Mulish', sans-serif; */
            font-size: 2.3rem;
            font-weight: 700;
            line-height: 2.5rem;
            text-align: center;
            position: absolute;
            
            
        }
    
    .LeftDiv{
        height: 9rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        top: 7%;
    }
    .Button{
        position:relative;
         left: -28rem;
         top: 12%;
        width:22%;
        /* height:13%; */
       
        font-size:1.8rem;
        font-weight: bolder;
        color:#0f4d70;
        border-radius:22px;
        background-color:white;
        border:4px solid white;
        
        /* cursor:pointer; */
        transition-duration:0.4s;
    }
    .cardpos {
        position: absolute;
        top: 24%;
        left: 2%;
        width: 96%;
        height: 65%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 3rem;
        flex-wrap: wrap;
        overflow-y: scroll;
        cursor: pointer;
       
        /* height: 90vh; */
    }
   
      
}
@media (max-width:710px) {
    .ProfilePic{
        height: 6.5rem;
        width: 6.5rem;
    }
    .NameOf{
font-size: 2.1rem;
    }
    .ktjid{
font-size: 1.3rem;
    }
    .cardpos{
        justify-content: center;
    }
    
}
@media (max-width: 640px){
    .RewardP{
        font-size: 1rem;
    }
    .Button{
    position:relative;
   
    top: 11%;
   width:28%;
   /* height:13%; */
  
   font-size:1.5rem;
}}
@media (max-width: 555px){
.RewardP{
    font-size: 0.9rem;
    flex-direction: row;
    
}
.RewardP span {
    /* background-color: red; */
    margin-top: -1rem;
}
.RewardP div {
    /* background-color: red; */
    margin-top: -0.1rem;
}
.NameOf{
    font-size: 2rem;
}
.Button{
    width:11rem;
    top: 11.5%;
    font-size:1.2rem;
}
.cardpos{
    top: 25%;
}
}
@media (max-width: 410px){
    .Button{
        width:9rem;
        top: 10.5%;
        font-size:1rem;
    }
    .cardpos{
        top: 30%;
        width: 95%;
    }
   
}
@media (max-width: 396px){
     .TotalDiv{
        width: 114%;
        height: 114%;
     }
     .LeftDiv{
        top:6%;
     }
    .cardpos{
        top: 23%;
        width: 95%;
    }
    .ProfilePic{
        left:1%;
    }
 }
    @media (max-width: 325px){
        .TotalDiv{
           width: 126%;
           height: 129%;
        }
       .cardpos{
           top: 23%;
           width: 95%;
       }
    .LeftDiv{
        top:5.4rem;
    }}
       @media (max-width: 294px){
        .TotalDiv{
           width: 142%;
           height: 140%;
        }
       .cardpos{
           top: 29%;
           width: 95%;
       }}
       @media (max-width: 260px){
        .TotalDiv{
           width: 160%;
           height: 160%;
        }
       .cardpos{
           top: 27%;
           width: 95%;
       }}
