@import url("https://fonts.googleapis.com/css2?family=Oxygen&family=Montserrat:wght@400;600&family=Patua+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
/*  
.container {
} */

.pointer {
  cursor: pointer;
}

.homeMain {
  min-width: 100%;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  padding: 0 4rem;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.bgCoverImg {
  width: 50%;
  margin-top: 7rem;
  max-width: 60rem;
  object-fit: cover;
  object-position: center;
  border: solid #ffffff;
  padding-left: 4rem;
  border-width: 0 0 0 0.2rem;
  animation: rise-up 1.8s ease-out forwards;
}
.homeMain::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://i.postimg.cc/8C63n90y/landing1.jpg');

  /* adda graident  */
  background: linear-gradient(0deg, #000 0%, #0000 80%, #0000 100%), url('https://i.postimg.cc/8C63n90y/landing1.jpg');
  background-size: cover;
  filter: blur(2px);
}

.homeContent {
  display: flex;
  width: 50%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding-right: 2rem;
}

.spon {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
  padding: 2rem 1rem;
  font-size: 1.7rem;
  animation: rise-up 2s ease-out forwards;
}

.spon img {
  width: 24rem;
}

.heading1 {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 8rem;
  max-width: 80rem;
  margin-bottom: 2rem;
  line-height: 8rem;
  letter-spacing: 0.25rem;
  color: #ffffff;
  text-shadow: 0px 0px 4rem #342e2e;
  animation: rise-up 1.8s ease-out forwards;
  padding-bottom: 1rem;
}

.heading3 {
  font-family: 'Mulish', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  max-width: 80rem;
  margin: 1rem 3rem;
  margin-bottom: 2rem;
  text-align: center;
  text-shadow: 0px 0px 4rem #00000044;
  animation: rise-up 2.2s ease-out forwards;
  z-index: 2;
}

@keyframes rise-up {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }

  50% {
    opacity: 0;
    transform: translateY(5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-cta-container {
  position: relative;
  display: inline-block;
  padding: 0.5rem;
  margin: 0.3rem;
  cursor: pointer;
  text-align: center;
  border: none;
  background-color: black;
}

.reg {
  margin-left: 2rem;
}

.animated-cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  border: none;
  margin: 4rem 2rem 0 3rem;
  font-size: 2rem;
  font-family: 'Mulish', sans-serif;
  font-weight: bold;
  border-radius: 1rem;
  color: black;
  background: linear-gradient(0deg, #e3ea0b, #e9fe27);
  box-shadow: 0 0 4rem #000000, 0 0.1rem 0.5rem #00000044;
  width: max-content;
  max-width: calc(100% - 3rem);
  transition: 0.25s;
  animation: rise-up 2.4s ease-out forwards;
}

.animated-cta-button:hover .animated-cta-button {
  transform: scale(1.05);
}

.btn-main-icon-block {
  position: absolute;
  background-color: #101403;
  width: 1rem;
  height: 1rem;
  transition: all 0.3s ease;
  z-index: 0;
}

/* Positioning the blocks */
.is-left {
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
}

.is-top {
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
}

.is-right {
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
}

.is-bottom {
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}

/* Animations on hover */
.animated-cta-button:hover .is-left,
.animated-cta-button:hover .is-right {
  width: 100%;
  left: 0;
  right: 0;
}

.animated-cta-button:hover .is-top,
.animated-cta-button:hover .is-bottom {
  height: 100%;
  top: 0;
  bottom: 0;
}

.animated-cta-button:hover .is-left,
.animated-cta-button:hover .is-right,
.animated-cta-button:hover .is-top,
.animated-cta-button:hover .is-bottom {
  background-color: #101206;
}

.bubbles {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: .5;
}

.bubble10 {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  animation: float 8s ease-in-out infinite, blink 4s infinite;
}

.bubble2 {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  animation: float 8s ease-in-out infinite, blink 3s infinite;
}

.bubble3 {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  animation: float 8s ease-in-out infinite, blink 5s infinite;
}

.bubble4 {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  animation: float 8s ease-in-out infinite, blink 4.5s infinite;
}

.bubble5 {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  animation: float 8s ease-in-out infinite, blink 4s infinite;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(-1rem) translateX(0);
  }

  50% {
    transform: translateY(1rem) translateX(0);
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}


@keyframes textAnimation {
  0% {
    color: rgb(112, 222, 28);
    transform: scale(1);
  }

  50% {
    color: rgb(17, 13, 5);
    transform: scale(1.1);
  }

  100% {
    color: yellow;
    transform: scale(1);
  }
}

.animated-cta-button:hover {
  box-shadow: 0 0 4rem #e6ff02, 0 0.1rem 0.5rem #e6ff02;
}

.info {
  color: white;
  opacity: 0.9;
  font-family: 'Mulish', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 1rem;
  text-shadow: 0 0 1rem #000000, 0 0 2rem #000000, 0 0 3rem #000000;
  text-align: center;
  animation: rise-up 2.6s ease-out forwards;
}

.applicationId {
  font-weight: bold;
  font-family: monospace;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  background: #000000bb;
  color: #e6ff02;
  text-decoration: underline;
}

@media only screen and (min-width: 720px) {
  .bubble10 {
    left: 60vw;
    top: 30vh;
  }

  .bubble2 {
    top: 40vh;
    left: 25vw;
  }

  .bubble3 {
    top: 45vh;
    left: 70vw;
  }

  .bubble4 {
    top: 55vh;
    left: 45vw;
  }

  .bubble5 {
    top: 50vh;
    left: 30vw;
  }
}

@media only screen and (max-width: 1440px) {
  .heading1 {
    font-size: clamp(4rem, 5vw, 8rem);
    line-height: clamp(2rem, 5vw, 8rem);
    letter-spacing: 0.1rem;
  }

  .heading3,
  .btn,
  .info,
  .applicationId {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
  }

  .btn,
  .info,
  .applicationId {
    font-size: clamp(1.5rem, 2.5vw, 2rem);
  }
}

@media only screen and (max-width: 720px) {
  .homeMain {
    flex-direction: column-reverse;
  }

  .animated-cta-button {
    font-size: 1.5rem;
  }

  .btn-main-icon-block {
    width: 0.8rem;
    height: 0.8rem;
  }

  .bubble10 {
    top: 24vh;
    left: 70vw;
  }

  .bubble2 {
    top: 50vh;
    left: 45vw;
  }

  .bubble3 {
    top: 40vh;
    left: 85vw;
  }

  .bubble4 {
    top: 30vh;
    left: 10vw;
  }

  .bubble5 {
    top: 50vh;
    left: 10vw;
  }

  .homeContent {
    width: 100%;
    height: max-content;
  }

  .bgCoverImg {
    width: 100%;
    height: max-content;
    max-width: 40rem;
    border: none;
    padding-left: 0;
    padding-bottom: 2rem;
  }

  .heading1 {
    font-size: clamp(4rem, 6vw, 8rem);
    line-height: clamp(5rem, 6vw, 8rem);
    letter-spacing: 0.1rem;
  }

  .heading3,
  .btn,
  .info,
  .applicationId {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
  }

  .btn,
  .info,
  .applicationId {
    font-size: clamp(1.5rem, 3vw, 2rem);
  }
}

@media only screen and (max-width: 480px) {
  .homeMain {
    padding: 0 2rem;
  }

  .bgCoverImg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .spon {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }

  .spon img {
    width: 20rem;
    cursor: pointer;
  }

  .bubble10 {
    top: 24vh;
    left: 65vw;
  }

  .bubble2 {
    top: 55vh;
    left: 45vw;
  }

  .bubble3 {
    top: 40vh;
    left: 75vw;
  }

  .bubble4 {
    top: 30vh;
    left: 10vw;
  }

  .bubble5 {
    top: 45vh;
    left: 3vw;
  }

  .animated-cta-container {
    padding: 0.5rem;
    margin: 0.1rem;
  }
}